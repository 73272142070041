/* eslint-disable @typescript-eslint/ban-types */

export function Pure<T = any>(
  _target: Object,
  propertyKey: string,
  { enumerable, value }: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> {
  const original = value as unknown as Function;

  return {
    enumerable,
    get(): T {
      let previousArgs: ReadonlyArray<unknown> = [];
      let previousResult: any;

      const patched = (...args: Array<unknown>) => {
        if (
          previousArgs.length === args.length &&
          args.every((arg, index) => arg === previousArgs[index])
        ) {
          return previousResult;
        }

        previousArgs = args;
        previousResult = original(...args);

        return previousResult;
      };

      Object.defineProperty(this, propertyKey, {
        value: patched,
      });

      return patched as any;
    },
  };
}
